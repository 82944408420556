/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Tenders  Details  
  3. Brid Customer Modal
  4. Media Query
 ------------------------*/

//  Variable Style File
@import "../variables.scss";

/* ------- Tender Details Style Start ------- */
.body-conetnt-header {
  .tender_details {
    flex-direction: row;
    gap: 400px;

    span {
      font-size: $text-13;
      font-weight: $text-500;
      text-transform: uppercase;
      line-height: $leading-22;
      color: $mutedone;
    }

    .tender_info {
      gap: 130px;

      p {
        white-space: nowrap;
        font-size: $text-18;
        margin-top: 12px;
        font-weight: $text-600;
        line-height: $leading-25;
      }
    }

    .total_value {
      p {
        margin-top: 12px;
        font-size: $text-20;
        font-weight: $text-600;
        line-height: $leading-20;
        margin-top: 12px;
      }
    }
  }
}

.custom-table {
  [aria-hidden="true"] {
    display: none;
  }
}

/* ------- Tender Details Style End ------- */
/* ------- Brid Customer Modal Style Start ------- */

[aria-modal="true"] {
  margin: 0 !important;
  // max-width: 956px;
}

.won_text{
  color: $green !important;
  text-transform: uppercase !important;
}

.brid-modal-content { 

  .lot_detail {
    padding: 14px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    span {
      font-size: $text-13;
      font-weight: $text-500;
      text-transform: uppercase;
      line-height: $leading-22;
      color: $mutedone;
    }

    p {
      white-space: nowrap;
      font-size: $text-16;
      margin-top: 10px;
      font-weight: $text-600;
      line-height: $leading-16;
    }

    .lotdetail_info {
      flex-direction: row;
      gap: 100px;
    }
  }

  .won_details {
    height: 76px;
    display: flex;
    align-items: center;
  }

  .won-content {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 20px;

    p {
      font-size: $text-16;
      font-weight: $text-500;
      line-height: $leading-25;
      color: $dark;
      white-space: nowrap;
    }
  }


  .dropstabls{
    height: calc(100% - 261px) !important;
  }

  
}

/* ------- Brid Customer Modal Style End ------- */

/* ------- Media Query Start ------- */

@media screen and (max-width: 1730px) {
  .body-conetnt-header {
    .tender_details {
      gap: 230px;
    }

    .tender_info {
      gap: 100px !important;
    }
  }
}

@media screen and (max-width: 1535px) {
  .body-conetnt-header {
    .tender_details {
      gap: 150px;
    }

    .tender_info {
      gap: 100px !important;
    }
  }
}

@media screen and (max-width: 1400px) {
  .body-conetnt-header {
    .tender_details {
      gap: 30px;
      flex-direction: column;
    }

    .tender_info {
      gap: 30px !important;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1023px) {
  .body-conetnt-header {
    .tender_details {
      span {
        font-size: $text-12;
      }
      .tender_info {
        p {
          font-size: $text-16 !important;
          line-height: $leading-20;
          margin-top: 10px;
        }
      }

      .total_value {
        p {
          font-size: $text-18;
          line-height: $leading-18;
          margin-top: 10px;
        }
      }
    }
  }

  .brid-modal-content {
    .lot_detail {
      padding: 14px 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      span {
        font-size: $text-12;
      }

      p {
        font-size: $text-15;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .won-content {
    p {
      font-size: $text-15 !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .body-conetnt-header {
    .tender_info {
      flex-direction: column;
      justify-content: center;
    }
  }

  .brid-modal-content {
    .lot_detail {
      .lotdetail_info {
        gap: 80px;
      }
    }
  }

  .won-content {
    height: 100% !important;
    align-items: flex-start !important;
    padding: 20px  !important;
    gap: 16px;
    flex-direction: column;
    flex-direction: column !important;
    p {
      font-size: $text-14;
    }
  }
}

@media screen and (max-width: 600px) {
  .brid-modal-content {
    .lot_detail {
      flex-direction: column;
      gap: 30px;
      .lotdetail_info {
        gap: 70px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .brid-modal-content {
    .lot_detail {
      .lotdetail_info {
        flex-direction: column;
        gap: 20px;

        p {
          text-align: left !important;
          margin-top: 4px;
        }
      }
    }
  }
}

/* ------- Media Query End ------- */
