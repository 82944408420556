/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Add-New Tenders   
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../variables.scss";

/* ------- Add-New Tender Style Start ------- */
.new-tender-content {
    label {
        min-width: 262px;
        font-size: $text-15;
        font-weight: $text-600;
        line-height: $leading-25;
        color: $darkone;
    }

    .tender-name {
        max-width: 555px;
        [data-slot="input-wrapper"] {
            border-radius: $rounded-05 !important;

            &:hover {
                background-color: transparent !important;
            }
        }
    }

    .calender-img {
        position: absolute;
        top: 50% !important;
        transform: translateY(-50%);
        left: 16px;
    }

    .dates {
        input {
            width: 319px;
            border: 1px solid $mutedseven;
            border-radius: $rounded-05;
            height: 44px;
            padding-left: 52px;
        }

        input {
            position: absolute;
            padding-left: 52px;
        }
        width: 319px;

        [data-slot="input-wrapper"] {
            border-radius: $rounded-05 !important;

            &:hover {
                background-color: transparent !important;
                cursor: pointer !important;
            }
        }
    }
}

.divider {
    max-width: 1024px;
}

.text-red-500 {
    color: #ef4444; /* This is the default red-500 color code */
}

.form_responsive {
    width: calc(100% - 0px);

    .lotdetails {
        p {
            font-size: $text-16;
            line-height: $leading-22;
            font-weight: $text-500;
            color: $darkone;
        }
        .comansize {
            width: 186px;
        }

        .bigsize {
            width: 297px;
        }
    }

    .addlotdetails {
        .comaninputs {
            width: 186px;

            [data-slot="input-wrapper"] {
                border-radius: $rounded-05 !important;

                &:hover {
                    background-color: transparent !important;
                }
            }
        }

        .biginputs {
            width: 297px !important;

            [data-slot="input-wrapper"] {
                border-radius: $rounded-05 !important;

                &:hover {
                    background-color: transparent !important;
                }
            }
        }
    }

    .addlotdetail_btn {
        font-size: $text-16;
        font-weight: $text-500;
        line-height: $leading-22;
        color: $dark-blue;
        margin-top: 30px;
        cursor: pointer;
    }
}

.tender-foot {
    border-top: 1px solid $graysix;
    background-color: $white;
    z-index: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    button {
        border-radius: $rounded-05;
        text-transform: uppercase;
        font-size: $text-14;
        padding: 12px 40px;
        font-weight: $text-500;

        &:hover {
            opacity: 100%;
        }
    }

    .cancletender_btn {
        background-color: transparent;
        &:hover {
            background-color: $darkred;
            color: $white;
        }
    }

    .savetender_btn {
        background-color: $dark-blue;
        font-weight: $text-500;
        color: $white;
    }

    .small-loader {
        border: 3px solid #004f9c;
        border-top: 3px solid #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
      }
}
/* ------- Add-New Tender Style End ------- */

/* ------- Media Query Start ------- */
@media screen and (max-width: 1535px) {
    .form_responsive {
        width: calc(100% - -200px);
    }
}

@media screen and (max-width: 1279px) {
    .form_responsive {
        width: calc(100% - -450px);
    }
}

@media screen and (max-width: 1023px) {
    .new-tender-content {
        label {
            min-width: 240px;
            font-size: $text-14;
            line-height: $leading-20;
        }
    }

    .tender-foot {
        left: 0;
    }

    .form_responsive {
        width: calc(100% - -450px);

        .lotdetails {
            p {
                font-size: $text-15;
            }
        }

        .addlotdetail_btn {
            font-size: $text-15;
        }
    }
}

@media screen and (max-width: 767px) {
    .new-tender-content {
        label {
            min-width: auto;
            font-weight: $text-500;
        }

        .tender-name {
            max-width: 100% !important;
        }

        .dates {
            width: 100%;
        }
    }

    .form_responsive {
        width: calc(100% - -650px);

        .lotdetails {
            p {
                font-size: $text-14;
            }
        }

        .addlotdetail_btn {
            font-size: $text-14;
        }
    }

    .tender-foot {
        button {
            padding: 12px 36px;
        }
    }
}

@media screen and (max-width: 575px) {
    .form_responsive {
        width: calc(100% - -800px);
    }
}

@media screen and (max-width: 399px) {
    .form_responsive {
        width: calc(100% - -940px);
    }
}
/* ------- Media Query End ------- */
