/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Add-New Customer   
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../variables.scss";

/* ------- Add-New Customer Style Start ------- */
.new-customer-content {
    label {
        min-width: 262px;
        font-size: $text-15;
        font-weight: $text-600;
        line-height: $leading-25;
        color: $darkone;
    }

    .new-customers-name {
        max-width: 319px;
        [data-slot="input-wrapper"] {
            border-radius: $rounded-05 !important;

            &:hover {
                background-color: transparent !important;
            }
        }
    }

    .upload {
        width: 150px !important;
        height: 140px !important;
        border: 1px solid $graytwelve;
        border-radius: $rounded-05 !important;
        overflow: hidden !important;
        background-color: $light-wheat !important;

        .relative.shadow-none {
            height: 100% !important;
            width: 100% !important;
        }

        input {
            z-index: 10 !important;
            cursor: pointer;
        }
    }
}

.customer-foot {
    border-top: 1px solid $graysix;
    background-color: $white;
    z-index: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    button {
        border-radius: $rounded-05;
        text-transform: uppercase;
        font-size: $text-14;
        padding: 12px 40px;
        font-weight: $text-500;

        &:hover {
            opacity: 100%;
        }
    }

    .cancletender_btn {
        background-color: transparent;
        &:hover {
            background-color: $darkred;
            color: $white;
        }
    }

    .savetender_btn {
        background-color: $dark-blue;
        font-weight: $text-500;
        color: $white;
    }

    .small-loader {
        border: 3px solid #004f9c;
        border-top: 3px solid #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
    }
}

.error-message {
    color: $darkred;
    font-size: $text-13;
    margin-top: $text-500;
    padding: 2px 0 0 4px;
}

.crop-controls {
    display: flex;
    position: absolute;
    justify-content: center;
    margin-top: 20px; /* Adjust as needed for spacing */
}

/* ------- Add-New Customer Style End ------- */

/* Modal styles */
.backdrops{
    background-color: $light-black;
}

/* ------- Media Query Start ------- */

@media screen and (max-width: 1023px) {
    .new-customer-content {
        label {
            min-width: 240px;
            font-size: $text-14;
            line-height: $leading-20;
        }
    }

    .customer-foot {
        left: 0;
    }
}

@media screen and (max-width: 767px) {
    .new-customer-content {
        label {
            min-width: auto;
            font-weight: $text-500;
        }

        .new-customers-name {
            max-width: 100% !important;
        }
    }

    .customer-foot {
        button {
            padding: 12px 36px;
        }
    }
}

/* ------- Media Query End ------- */
