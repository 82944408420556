/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Tenders  Details  
  3. Brid Customer Modal
  4. Media Query
 ------------------------*/

//  Variable Style File
@import "../../variables.scss";

/* ------- Brid Customer Modal Style Start ------- */

[aria-modal="true"] {
    margin: 0 !important;
}

.customs_inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 600px;

    [data-slot="input-wrapper"] {
        border: 1px solid $mutedseven;

        &:hover {
            border: 1px solid $dark-blue !important;
            background-color: transparent !important;

            svg {
                fill: $dark-blue !important;
            }
        }
    }

    .group[data-focus="true"] .group-data-\[focus\=true\]\:bg-default-100 {
        background-color: transparent !important;
        border-color: $dark-blue !important;

        svg {
            fill: $dark-blue !important;
        }
    }

    input {
        font-size: $text-15;
        color: $mutedten;
        &:focus {
            color: $dark-blue !important;
        }

        &:hover {
            &::placeholder {
                color: $dark-blue !important;
            }
        }
    }

    .form_btn {
        padding: 10px 40px;
        border-radius: 5px;
        background-color: $dark-blue;
        color: $white;
        font-size: $text-16;
        font-weight: $text-500;
        text-align: center;
    }
}

/* ------- Brid Customer Modal Style End ------- */

/* ------- Media Query Start ------- */

@media screen and (max-width: 1023px) {

    .customs_inputs {
        max-width: 570px;
    }
}

@media screen and (max-width: 639px) {

        .customs_inputs {
            max-width: 100%;
        }
    
}

/* ------- Media Query End ------- */
