/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Login   
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../variables.scss";

/* ------- Login Style Start ------- */
.login_bg {
  background-image: url(../../public/login-img/login-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-content {
  align-items: center;
  gap: 105px;

  .login_image {
    img {
      max-width: 330px;
      max-width: 330px;
    }
  }

  h1 {
    font-size: $text-40;
  }

  .customs_inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 600px;

    [data-slot="input-wrapper"] {
      border: 1px solid $mutedseven;

      &:hover {
        border: 1px solid $dark-blue !important;
        background-color: transparent !important;

        svg {
          fill: $dark-blue !important;
        }
      }
    }

    .group[data-focus="true"] .group-data-\[focus\=true\]\:bg-default-100 {
      background-color: transparent !important;
      border-color: $dark-blue !important;

      svg {
        fill: $dark-blue !important;
      }
    }

    input {
      font-size: $text-15;
      color: $mutedten;
      &:focus {
        color: $dark-blue !important;
      }

      &:hover {
        &::placeholder {
          color: $dark-blue !important;
        }
      }
    }

    .form_btn {
      padding: 10px 40px;
      border-radius: 5px;
      background-color: $dark-blue;
      color: $white;
      font-size: $text-16;
      font-weight: $text-500;
      text-align: center;
    }

    .small-loader {
      border: 3px solid #004f9c;
      border-top: 3px solid #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
    }
  }
}
/* ------- Login Style End ------- */
/* ------- Media Query Start ------- */
@media screen and (max-width: 1279px) {
  .login-content {
    gap: 88px;
  }
}

@media screen and (max-width: 1023px) {
  .login_bg {
    height: 100%;
    margin: 43px 0;
  }

  .login-content {
    gap: 50px;

    .login_image {
      display: flex;
      align-items: center;
      justify-content: center !important;

      img {
        max-width: 300px;
        max-width: 300px;
      }
    }

    .customs_inputs {
      max-width: 570px;
    }
  }
}

@media screen and (max-width: 639px) {
  .login_bg {
    display: block !important;
  }

  .login-content {
    column-gap: 0;
    row-gap: 100px;

    .login_image {
      img {
        max-width: 100px;
        max-width: 300px;
      }
    }

    h1 {
      font-size: $text-30;
    }

    .customs_inputs {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 575px) {
  .login-content {
    row-gap: 100px;

    .login_image {
      img {
        max-width: 280px;
        max-width: 280px;
      }
    }

    input {
      font-size: $text-14 !important;
    }
  }
}
/* ------- Media Query End ------- */
