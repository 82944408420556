/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Tenders  Details  
  2. Active Customer Details
  3. Media Query
 ------------------------*/

/* ------- Active Customer Details Style Start ------- */
.activetabs {
    [data-selected="true"] {
        span {
            background-color: transparent !important;
        }
    }
}

.changebg{
    .dark\:bg-default {
        background-color: transparent !important;
    }
}

.customer_details {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 80px;
    row-gap: 24px;

    span {
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 22px;
        color: #817e7e;
        white-space: nowrap;
    }

    p {
        white-space: nowrap;
        font-size: 17px;
        margin-top: 12px;
        font-weight: 600;
        line-height: 25px;
    }
}

.details-tabs{
    display: none !important;
}


/* ------- Active Customer Details Style Start ------- */

/* ------- Media Query Style Start ------- */

@media screen and (max-width: 1535px) {
    .customer_details {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1279px) {
    .customer_details {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1023px) {
    .customer_details {
        span {
            font-size: 12px;
        }

        p {
            font-size: 16px;
            margin-top: 10px;
            line-height: 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    .customer_details {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 639px) {
    .customer_details {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }
}

/* ------- Media Query Style End ------- */
