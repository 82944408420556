// ----------- Color Variables Start ----------- //
$dark-blue: #004f9c;
$blue: #0f75bc;
$blueone: #05399e;
$bluetwo: #0a1a71;
$light-blue: #004f9c2b;
$white: #ffffff;
$light-white: #e7e7e7;
$light-black: #0000004a;
$black: #000000;
$blackone: #010101;
$blacktwo: #00000052;
$darkmuted: #4f4f4f;
$muted: #777777;
$mutedone: #817e7e;
$mutedtwo: #838383;
$mutedthree: #7e7e7e;
$mutedfour: #ebebeb;
$mutedfive: #dedede;
$mutedsix: #595959;
$mutedseven: #c1c1c1;
$mutedeight: #e6e6e6;
$mutednine: #c5c5c5;
$mutedten: #71717a;
$dark: #3d3d3d;
$darkone: #494949;
$darktwo: #3d3b3b;
$lightgray: #f9f9f9;
$gray: #575757;
$grayone: #c4c4c4;
$graytwo: #eaeaea;
$graythree: #d7d7d7;
$grayfour: #5f5f5f;
$grayfive: #dddddd;
$graysix: #e4e4e4;
$grayseven: #f8f8f8ad;
$grayeight: #f8f8f8ab;
$graynine: #929292;
$grayten: #606060;
$grayeleven: #f2f2f2;
$darkred: #d00000;
$red: #bc0f0f;
$light-red: #ff000026;
$redone: #ff4b4b;
$redtwo: #ff0000;
$redthree: #ff4747;
$orange: #fa9600;
$purple: #2c3192;
$wheat: #f3f3f3;
$green: #008c06;
$light-green: #d2e8d3;
$tometo: #ff4c00;
$graytwelve: #e8e8e8;
$light-wheat: #fdfdfd;
// ----------- Color Variables End ----------- //

// ----------- Font Size Variables Start ----------- //
$text-12: 12px;
$text-13: 13px;
$text-14: 14px;
$text-15: 15px;
$text-16: 16px;
$text-17: 17px;
$text-18: 18px;
$text-20: 20px;
$text-30: 30px;
$text-32: 32px;
$text-33: 33px;
$text-34: 34px;
$text-35: 35px;
$text-36: 36px;
$text-37: 37px;
$text-38: 38px;
$text-39: 39px;
$text-40: 40px;
$text-41: 41px;
$text-42: 42px;
$text-43: 43px;
$text-44: 44px;
$text-45: 45px;
$text-46: 46px;
$text-47: 47px;
$text-48: 48px;
$text-49: 49px;
$text-50: 50px;
// ----------- Font Size Variables End ----------- //

// ----------- Font Weight Variables Start ----------- //
$text-400: 400;
$text-500: 500;
$text-600: 600;
$text-700: 700;
$text-800: 800;
$text-900: 900;
// ----------- Font Weight Variables End ----------- //

// ----------- Line Height Variables Start ----------- //
$leading-10: 10px;
$leading-11: 11px;
$leading-12: 12px;
$leading-13: 13px;
$leading-14: 14px;
$leading-15: 15px;
$leading-16: 16px;
$leading-17: 17px;
$leading-18: 18px;
$leading-19: 19px;
$leading-20: 20px;
$leading-21: 21px;
$leading-22: 22px;
$leading-23: 23px;
$leading-24: 24px;
$leading-25: 25px;
$leading-26: 26px;
$leading-27: 27px;
$leading-28: 28px;
$leading-29: 29px;
$leading-30: 30px;
$leading-31: 31px;
$leading-32: 32px;
$leading-33: 33px;
$leading-34: 34px;
$leading-35: 35px;
$leading-36: 36px;
$leading-37: 37px;
$leading-38: 38px;
$leading-39: 39px;
$leading-40: 40px;
$leading-41: 41px;
$leading-42: 42px;
$leading-43: 43px;
$leading-44: 44px;
$leading-45: 45px;
$leading-46: 46px;
$leading-47: 47px;
$leading-48: 48px;
$leading-49: 49px;
$leading-49: 49px;
$leading-50: 50px;
// ----------- Line Height Variables End ----------- //

// ----------- Border Radius Variables Start ----------- //
$rounded-04: 4px;
$rounded-05: 5px;
$rounded-06: 6px;
$rounded-08: 8px;
$rounded-10: 10px;
$rounded-12: 12px;
$rounded-14: 14px;
$rounded-16: 16px;
$rounded-18: 18px;
$rounded-20: 20px;
$rounded-22: 22px;
$rounded-24: 24px;
$rounded-circle: 50%;
// ----------- Border Radius Variables End ----------- //
