/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Need-Approval   
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../variables.scss";

/* ------- Active -Customer Style Start ------- */

.custom-table {
    tbody {
        tr {
            td {
                .switch-toggle {
                    [aria-hidden="true"] {
                        display: block;

                        span {
                            margin-top: 4px;
                        }
                    }

                    [aria-hidden="true"] {
                        display: block;
                    }

                    .group[data-selected="true"] {
                        .group-data-\[selected\=true\]\:bg-primary {
                            background-color: $green;
                        }
                    }
                }
            }
        }
    }
}

.moadl_btns {
    button {
        border-radius: $rounded-05;
        text-transform: uppercase;
        padding: 8px 30px;

        &:hover {
            opacity: 100%;
        }
    }

    .cancletender_btn {
        background-color: transparent;
        &:hover {
            background-color: $darkred;
            color: $white;
        }
    }

    .savetender_btn {
        background-color: $dark-blue;
        color: $white;
    }
}

/* ------- Active -Customer Style Start ------- */
