/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Header  
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../../variables.scss";

/* ------- Header Style Start ------- */
header {
    // z-index: 1000001;
    // margin-right: 22px;
    background-color: $white;
    z-index: 2;
    .header {
        padding: 15px 20px 15px 20px;
        border-bottom: 1px solid $mutedfour;

        h2 {
            font-size: $text-32;
            line-height: $leading-40;
        }

        .profile-icon {
            height: 37px;
            width: 37px;
            background-color: $wheat;
            border-radius: $rounded-circle;

            img {
                height: 37px;
                width: 37px;
                border-radius: $rounded-circle;
            }
        }
    }
}

/* ------- Header Style End ------- */

/* ------- Media Query Start ------- */
@media screen and (max-width: 1023px) {
    header {
        margin-right: 0px;
        .header {
            padding: 15px 20px 13px 20px;

            h2 {
                font-size: $text-32;
                line-height: $leading-40;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    header {
        .header {
            h2 {
                display: none;
            }
        }
    }
}
/* ------- Media Query End ------- */
